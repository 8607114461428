body {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

.slot-machine {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
}

.title {
  text-align: center;
  color: #333;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.slot-display {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.slot {
  font-size: 48px;
  background-color: #ffd700;
  padding: 20px;
  margin: 0 5px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.slot.spinning {
  animation: spin 0.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(-2px);
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.coin-display {
  font-size: 1.2em;
  font-weight: bold;
}

.bet-control {
  display: flex;
  align-items: center;
}

.bet-control button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bet-control button:hover {
  background-color: #45a049;
}

.spin-button,
.reset-button {
  width: 100%;
  padding: 10px;
  font-size: 1.2em;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 10px;
}

.spin-button {
  background-color: #4caf50;
}

.spin-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.reset-button {
  background-color: #f44336;
}

.reset-button:hover {
  background-color: #d32f2f;
  transform: scale(1.05);
}

.message {
  text-align: center;
  margin: 10px 0;
  font-weight: bold;
  color: #333;
}

.win-conditions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.win-condition {
  display: flex;
  justify-content: space-between;
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9em;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

button:disabled:hover {
  transform: none;
}
